export const state = () => ({
  executiveDocument: [
    {
      code: '0',
      label: '−'
    }, {
      code: '1',
      label: 'Исполнительный лист'
    }, {
      code: '2',
      label: 'Нотариально удостоверенное соглашение об уплате алиментов'
    }, {
      code: '3',
      label: 'Акт по делу об административном правонарушении'
    }, {
      code: '4',
      label: 'Судебный приказ'
    }, {
      code: '5',
      label: 'Акт органа, осуществляющего контрольные функции'
    }, {
      code: '6',
      label: 'Удостоверение комиссии по трудовым спорам'
    }, {
      code: '7',
      label: 'Акт другого органа'
    }, {
      code: '8',
      label: 'Постановление судебного пристава-исполнителя'
    }, {
      code: '9',
      label: 'Исполнительная надпись нотариуса'
    }, {
      code: '10',
      label: 'Судебный акт по делу об административном правонарушении'
    }, {
      code: '11',
      label: 'Запрос центрального органа о розыске ребенка'
    }, {
      code: '12',
      label: 'Исполнительный документ, выданный компетентным органом иностранного государства'
    }, {
      code: '13',
      label: 'Удостоверение, выданное уполномоченным по правам потребителей финансовых услуг в порядке, предусмотренном Федеральным законом от 04.06.2018 № 123-ФЗ Об уполномоченном по правам потребителей финансовых услуг'
    }, {
      code: '14',
      label: 'Определение судьи о наложении ареста на имущество'
    }, {
      code: '15',
      label: 'Нотариально удостоверенное медиативное соглашение'
    }, {
      code: '16',
      label: 'Решение государственного инспектора труда о принудительном исполнении обязанности работодателя по выплатам, осуществляемым в рамках трудовых отношений'
    }
  ]
})

export const getters = {
  getDocumentList (state) {
    return state.executiveDocument
  }
}
