export enum ClassName {
  Emblem = 'emblem',
  PaidOff = 'paidOff',
  Pci = 'pci',
  Payment = 'payment',
  Sbp = 'sbp'
}

export const FeaturesList = [
  {
    imgDesk: require('~/assets/img/features/side-btn-emblem.svg'),
    imgHover: require('~/assets/img/features/side-btn-emblem-hover.svg'),
    title: 'Официальный источник данных',
    text: 'Сведения загружаются из банка данных ФССП России с использованием шлюзов Федерального казначейства',
    className: ClassName.Emblem,
    isOpen: false
  },
  {
    imgDesk: require('~/assets/img/features/side-btn-paid-off.svg'),
    imgHover: require('~/assets/img/features/side-btn-paid-off-hover.svg'),
    title: 'Гарантия погашения',
    text: 'Сведения об оплате поступают в Банк Данных ФССП России с использованием шлюзов Федерального казначейства',
    className: ClassName.PaidOff,
    isOpen: false
  },
  {
    imgDesk: require('~/assets/img/features/side-btn-bank.png'),
    imgHover: require('~/assets/img/features/side-btn-bank-hover.png'),
    title: 'Оплата любыми картами',
    text: 'Поддерживаем платежные системы\n' +
      'МИР, Visa, MasterCard',
    className: ClassName.Sbp,
    isOpen: false
  },
  {
    imgDesk: require('~/assets/img/features/side-btn-payment.svg'),
    imgHover: require('~/assets/img/features/side-btn-payment-hover.svg'),
    title: 'Квитанция об оплате',
    text: 'Квитанция будет отправлена на указанный Вами адрес электронной почты сразу же после оплаты',
    className: ClassName.Payment,
    isOpen: false
  }
]

export const FeaturesListPaymentPage = [
  {
    imgDesk: require('~/assets/img/features/side-btn-paid-off.svg'),
    imgHover: require('~/assets/img/features/side-btn-paid-off-hover.svg'),
    title: 'Гарантия погашения',
    text: 'Сведения об оплате поступают в Банк Данных ФССП России с использованием шлюзов Федерального казначейства',
    className: ClassName.PaidOff,
    isOpen: false
  },
  {
    imgDesk: require('~/assets/img/features/side-btn-emblem.svg'),
    imgHover: require('~/assets/img/features/side-btn-emblem-hover.svg'),
    title: 'Официальный источник данных',
    text: 'Сведения загружаются из банка данных ФССП России с использованием шлюзов Федерального казначейства',
    className: ClassName.Emblem,
    isOpen: false
  },
  {
    imgDesk: require('~/assets/img/features/side-btn-bank.png'),
    imgHover: require('~/assets/img/features/side-btn-bank-hover.png'),
    title: 'Оплата любыми картами',
    text: 'Поддерживаем платежные системы\n' +
      'МИР, Visa, MasterCard',
    className: ClassName.Sbp,
    isOpen: false
  },
  {
    imgDesk: require('~/assets/img/features/side-btn-payment.svg'),
    imgHover: require('~/assets/img/features/side-btn-payment-hover.svg'),
    title: 'Квитанция об оплате',
    text: 'Квитанция будет отправлена на указанный Вами адрес электронной почты сразу же после оплаты',
    className: ClassName.Payment,
    isOpen: false
  }
]
