
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import EmailConfirm from '~/components/account/EmailConfirm.vue'

@Component({
  components: {
    WebPushPopup: () => import('~/components/WebPushPopup.vue'),
    EmailConfirm
  },
  fetchOnServer: false
})
export default class extends Vue {
  @Prop() value!: boolean
  openWebPushPopup: boolean = false

  isWaitConfirm: boolean = true
  isOpenNotifications = false;

  async fetch () {
    await new Promise((resolve) => {
      window.addEventListener('load', resolve, { once: true })
    }).then(() => {
      return this.$store.dispatch('requisites/getList')
    })
    this.$emit('notificationsCount', this.$store.getters['requisites/getList'].length)
    if (this.$store.getters['requisites/getList'].length) {
      this.isOpenNotifications = true
    }
  }

  mounted () {
    this.$eventBus.$on('userConfirm', () => {
      this.$store.dispatch('requisites/getList').then(() => {
        if (this.$store.getters['requisites/getList'].length) {
          this.isOpenNotifications = true
        }
      })
      this.$store.dispatch('docs/getList')
    })
    this.$eventBus.$on('WebPushPermit', () => {
      this.openWebPushPopup = true
    })
  }

  openNotifyLink (url) {
    if (window.innerWidth < 768) {
      window.open(url, '_blank')
    }
  }

  get webPushId () {
    return this.$webPush.getId()
  }

  clickContainer (event) {
    const containerElem = document.querySelector('.account__background')
    if (containerElem === event.target) {
      this.$emit('input', false)
    }
  }

  async confirm () {
    await this.$fetch()
    await this.$store.dispatch('requisites/getList')
    this.isWaitConfirm = false
  }

  logout () {
    try {
      this.$axios.get(process.env.API_ENDPOINT + 'user/logout').then(() => {
        localStorage.removeItem('storedToken')
        window.location.reload()
        this.isWaitConfirm = true
      })
    } catch (err) {
    }
  }
}
