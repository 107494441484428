
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class DefaultBanner extends Vue {
  google: string = 'https://play.google.com/store/apps/details?id=com.easypay_fssp'
  apple: string = 'https://apps.apple.com/ru/app/id1467899675'
  activeIndex: number = 1

  mounted () {
    this.getActiveIndex()
  }

  getActiveIndex () {
    setTimeout(() => {
      ++this.activeIndex
      this.getActiveIndex()
    }, 3500)

    if (this.activeIndex === 4) {
      this.activeIndex = 1
    }
  }
}
