
import { Component, Vue } from 'nuxt-property-decorator'
import DefaultBanner from '~/components/banner/DefaultBanner.vue'

@Component({
  components: { DefaultBanner }
})
export default class extends Vue {
  scrollToTopPage () {
    (document.querySelector('body') as any).scrollIntoView({
      behavior: 'smooth'
    })
  }
}
