
import { Component, Vue } from 'nuxt-property-decorator'
import Account from '~/components/account/Account.vue'

@Component({
  components: {
  Account,
  WebPushPopup: () => import('~/components/WebPushPopup.vue')
  }
  })
export default class extends Vue {
  isOpened = false;

  notificationsReceived = false;
  notificationsCount: number | null = null;

  openWebPushPopup: boolean = false

  mounted () {
    this.$eventBus.$on('showPopup', (val) => {
      this.isOpened = val.isShow
    })
  }

  setNotifications (count: number) {
    this.notificationsReceived = true
    this.notificationsCount = count
  }
}
