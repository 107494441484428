
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ClassName, FeaturesList } from '~/constants/FeaturesList'

interface Feature {
  imgDesk: string;
  imgHover: string;
  title: string;
  text: string;
  className: ClassName;
  isOpen: boolean;
}

@Component
export default class extends Vue {
  name: 'Features'

  @Prop({ default: false })
  isColumn: boolean

  @Prop({ default: false })
  isTwoColumn: boolean

  @Prop({ default: () => FeaturesList })
  items: Feature[]

  @Prop({ default: true })
  isShowImg: boolean

  @Prop({ default: false })
  isActiveCard: boolean

  className = ClassName;

  toggleHideText (item: Feature, index: number) {
    if (window.innerWidth < 992) {
      this.items.forEach((elem, elemIndex) => {
        if (index === +elemIndex) {
          elem.isOpen = !(item.isOpen)
        } else {
          elem.isOpen = false
        }
      })
    }
  }
}
