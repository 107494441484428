/**
 * @param req
 * @param res
 * @param next
 */
export default function (req, res, next) {
  if (req.url.startsWith('/static/_articles/')) {
    res.setHeader('Cache-Control', 'no-store, no-cache, must-revalidate, proxy-revalidate')
    res.setHeader('Pragma', 'no-cache')
    res.setHeader('Expires', '0')
    res.setHeader('Surrogate-Control', 'no-store')
  }
  next()
}
