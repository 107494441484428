var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{},[_c('div',{staticClass:"side-buttons",class:{'side-buttons--column' : _vm.isColumn, 'side-buttons--2-column' : _vm.isTwoColumn }},[_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"side-buttons__item",on:{"click":function($event){return _vm.toggleHideText(item, index)}}},[_c('div',{staticClass:"side-buttons__content-wrapper"},[_c('div',{staticClass:"side-buttons__title-wrap"},[(_vm.isShowImg)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"side-buttons__icon",class:{
              'mod-emblem': item.className === _vm.className.Emblem,
              'mod-paidOff': item.className === _vm.className.PaidOff,
              'mod-pci': item.className === _vm.className.Pci,
              'mod-payment': item.className === _vm.className.Payment,
              'mod-sbp': item.className === _vm.className.Sbp
            },attrs:{"src":"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7","data-src":item.imgDesk,"alt":item.title,"width":"50","height":"50"}}):_vm._e(),_vm._v(" "),(_vm.isShowImg)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],staticClass:"side-buttons__icon side-buttons__icon--active",class:['mod-' + item.className],attrs:{"src":"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7","data-src":item.imgHover,"alt":item.title,"width":"50","height":"50"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"side-buttons__title"},[_vm._v("\n            "+_vm._s(item.title)+"\n          ")])]),_vm._v(" "),_c('div',[_c('div',{staticClass:"side-buttons__text",class:{'mod-show': item.isOpen}},[_vm._v("\n            "+_vm._s(item.text)+"\n          ")])])])])}),_vm._v(" "),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }