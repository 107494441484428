export const state = () => ({
  contestation: null
})

export const getters = {
  getContestation: state => state.contestation
}

export const mutations = {
  setContestation (state, contestation) {
    if (state.contestation === null) {
      state.contestation = {}
    }

    Object.assign(state.contestation, contestation)
  }
}
