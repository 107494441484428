import { Context } from '@nuxt/types'
import { Inject } from '@nuxt/types/app'

export class SearchAnalytics {
  /** @member {string} константа имени куки */
  private SEARCH_RETRY_DOCUMENT_TYPE_COOKIE_NAME = 'retry_doc_type'

  /**
   * Проверка повторного поиска. Поиск считается повторным, если в течение 10 минут
   * пользователь совершает очередную проверку по тому же реквизиту
   *
   * @param {string} documentType - тип проверяемого документа
   * @param {string | object} documentValue - значение проверяемого документа
   * @param {number} maxAge - время жизни устанавливаемой куки
   * @returns {boolean} result - является ли поиск повторным
   */
  isSearchRetry (documentType: string, documentValue: string | object, maxAge: number) {
    const cookieParts = [documentType]

    if (typeof documentValue === 'string') {
      cookieParts.push(documentValue)
    } else {
      cookieParts.push(...Object.values(documentValue))
    }

    const cookieName = cookieParts.map(part => part.toLowerCase().replaceAll(' ', '_')).join('.')
    const isCookieExists = document.cookie.split(';').some(cookie => cookie.includes(cookieName))

    /** если поиск не повторный, стираем куку для оплаты */
    if (isCookieExists) {
      this.setSearchRetryPaymentCookie(documentType, maxAge)
    } else {
      this.setSearchRetryPaymentCookie(documentType, 0)
    }

    document.cookie = cookieName + '=true;path=/;max-age=' + maxAge
    return isCookieExists
  }

  /**
   * Тип последнего документа
   *
   * @returns {string | null} result - тип документа, если таковой найден, иначе - null
   */
  getSearchRetryDocumentType () {
    const cookies = document.cookie.split(';')

    for (const cookie of cookies) {
      const regex = new RegExp(this.SEARCH_RETRY_DOCUMENT_TYPE_COOKIE_NAME + '=(.*)$')
      if (regex.test(cookie)) {
        const execResult = regex.exec(cookie)

        if (execResult && execResult[1]) {
          return execResult[1]
        }
      }
    }

    return null
  }

  /**
   * Отправляем событие повторной проверки на бэк
   *
   * @param {boolean} isAccrualsFound - найдены ли начисления
   * @returns {Promise<*>} promise
   */
  sendSearchRetryEvent (isAccrualsFound: boolean) {
    const documentType = this.getSearchRetryDocumentType()

    return window.$nuxt.$axios.get(process.env.API_ENDPOINT + 'user/event', {
      params: {
        event_action: isAccrualsFound ? 'retry_result' : 'retry_empty_result',
        accrual_type_id: documentType,
        lead_source_id: window.$nuxt.$route.query.lead_source_id,
        ...window.$nuxt.$ga.getStorage()
      }
    })
  }

  /**
   * Совершена ли оплата после повторного поиска
   *
   * @returns {boolean} result
   */
  isPaymentAfterRetrySearch () {
    return document.cookie.split(';').some(cookie => new RegExp(this.SEARCH_RETRY_DOCUMENT_TYPE_COOKIE_NAME + '=(.*)$').test(cookie))
  }

  /**
   * Отправляем событие об оплате после повторной проверки
   *
   * @returns {Promise<*>} promise
   */
  sendPaymentAfterRetrySearchEvent () {
    const documentType = this.getSearchRetryDocumentType()

    return window.$nuxt.$axios.get(process.env.API_ENDPOINT + 'user/event', {
      params: {
        event_action: 'payment_success_after_retry',
        accrual_type_id: documentType,
        lead_source_id: window.$nuxt.$route.query.lead_source_id,
        ...window.$nuxt.$ga.getStorage()
      }
    })
  }

  /**
   * Устанавливаем куку с типом последнего проверяемого документа
   *
   * @param {string} documentType - тип проверяемого документа
   * @param {number} maxAge - время жизни куки
   */
  setSearchRetryPaymentCookie (documentType: string, maxAge: number) {
    document.cookie = this.SEARCH_RETRY_DOCUMENT_TYPE_COOKIE_NAME + '=' + documentType + ';path=/;max-age=' + maxAge
  }
}

/**
 * @param {Context} ctx - nuxt контекст
 * @param {Inject} inject - функция добавления плагина
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function (ctx: Context, inject: Inject) {
  inject('searchAnalytics', new SearchAnalytics())
}

declare module 'vue/types/vue' {
  interface Vue {
    $searchAnalytics: SearchAnalytics
  }
}

declare module '@nuxt/types' {
  interface Context {
    $searchAnalytics: SearchAnalytics
  }

  interface NuxtAppOptions {
    $searchAnalytics: SearchAnalytics
  }
}
