import hash from 'object-hash'

export const state = () => ({
  state: {}
})

export const getters = {
  getState: (state) => {
    return state.state
  },
  getByDocument: state => (documentValue) => {
    return state.state[hash(documentValue)]
  }
}

export const mutations = {
  setSate (state, payload) {
    state.state = Object.assign(state.state, {
      [hash(payload.document)]: payload
    })
  },
  clearState (state) {
    for (const key in state.state) {
      delete state.state[key]
    }
  }
}
